export const getPayload = () => {
  const payload = JSON.parse(localStorage.getItem("AUTH-REQUEST-PAYLOAD"));
  if (!payload || payload === "") return {};
  if (!payload.perfil) return {};
  payload.validacaocnh = true;
  payload.historico = true;
  payload.level = 2;
  return payload;
};

export const getPayloadBiometria = () => {
  const payload = JSON.parse(localStorage.getItem("TOKEN-BIO-DATA"));
  if (!payload || payload === "") return {};
  return payload;
};

export const isAuthenticated = () => {
  return (
    getPayload() !== null && getPayload().exp * 1000 > new Date().getTime()
  );
};

export const isAuthenticatedBiometria = () => {
  return (
    getPayloadBiometria() !== null && getPayloadBiometria().exp * 1000 > new Date().getTime()
  );
};

export const getTokenBiometria = () => {
  if (JSON.parse(localStorage.getItem("TOKEN-BIO"))) {
    return JSON.parse(localStorage.getItem("TOKEN-BIO"));
  } else {
    return null;
  }
};

export const getToken = () => {
  if (JSON.parse(localStorage.getItem("AUTH-REQUEST-TOKEN"))) {
    return JSON.parse(localStorage.getItem("AUTH-REQUEST-TOKEN"));
  } else {
    return null;
  }
};

export const getUserId = () => {
  if (localStorage.getItem("userid")) {
    return localStorage.getItem("userid");
  } else {
    return null;
  }
};


export const onlogoutSystemBiometria = () => {
  return new Promise((response) => {
    localStorage.removeItem("TOKEN-BIO");
    localStorage.removeItem("TOKEN-BIO-DATA");


    response();
  });
};

export const onloginSystem = (value) => {
  return new Promise((response) => {
    localStorage.setItem("AUTH-REQUEST-PAYLOAD", JSON.stringify(value));
    localStorage.setItem("AUTH-REQUEST-TOKEN", JSON.stringify(value.token));
    response();
  });
};

export const onloginSystemBiometria = ({ token, dados }) => {
  return new Promise((response) => {
    localStorage.setItem("TOKEN-BIO", JSON.stringify(token));
    localStorage.setItem("TOKEN-BIO-DATA", JSON.stringify(dados));
    response();
  });
};

/*
function fnGetPerfil({ isnvl, isAdmin }) {
  if (isnvl) return 3;
  else if (isAdmin) return 2;
  else return 1;
}*/
