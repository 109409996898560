import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import Webcam from "react-webcam";
import * as faceapi from "@vladmandic/face-api";
import LogoExtended from "../../Assets/images/Logos/logoWhite.png";
import SweetAlert from "../../Utils/SweetAlert";
import logo from "../../Assets/images/Logos/logo_dataprove.png";

import "./FaceId.css";

let fase = 1;

let timerTimeout;
let time;

function FaceId({ setStep }) {
  const [status, setStatus] = useState("Iniciando processo");
  const [photo, setPhoto] = useState(null);
  const [processData, setProcessData] = useState(null);
  const [tentativas, setTentativas] = useState(1);
  const [inicia, setInicia] = useState(false);
  const [driverToken, setDriverToken] = useState(null);
  const [driverId, setDriverId] = useState(null);

  const history = useNavigate();
  const videoRef = useRef();
  const canvasRef = useRef();

  useEffect(() => {
    (async () => {
      const token = await asyncLocalStorage.getItem("@data-prove-biometria:driverToken");
      const id = await asyncLocalStorage.getItem("@data-prove-biometria:driverId");
      console.log(token)
      if (token && id) {
        setDriverToken(token);
        setDriverId(id);
      }
    })()

  }, []);

  const asyncLocalStorage = {
    getItem(key) {
      return Promise.resolve().then(function () {
        return localStorage.getItem(key);
      });
    }
  };

  useEffect(() => {
    loadModels();
    setStatus("Carregando a câmera");
  }, []);

  useEffect(() => {
    if (inicia) {
      runTimer();
    }
  }, [inicia]);

  const runTimer = () => {
    timerTimeout = window.setTimeout(() => {
      clearInterval(time);

      SweetAlert.small({ type: "error", title: "Tempo excedido!" });
      exit();
    }, 20000);
  };

  function exit() {
    clearInterval(time);
    /* canvasRef.current = null;
    let videoAux = document?.getElementById("my-video");
    videoAux?.srcObject?.getTracks()?.forEach((track) => track.stop());
    videoAux?.remove();

    document.getElementById("canvas")?.remove(); */
    clearTimeout(timerTimeout);

    fase = 1;
    setStep(1);

      window.location.reload();
  }

  useEffect(() => {
    let videoAux = document?.getElementById("my-video");
    if (!photo) return;
    clearTimeout(timerTimeout);
    setStatus("Processando a foto");

    if (photo.toBlob) {
      photo.toBlob(async function (blob) {
        var formData = new FormData();

        if (fase === 2) {
          /* setStatus("Pronto, aguarde mais um pouco"); */

          formData.append("image", blob, "image.jpg");
          formData.append("processo", processData.data);

          const d = await axios
            .post(
              `${process.env.REACT_APP_BASE}/validacaocnh/remoto/biometria/basica/fase-02/${driverId}`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${driverToken}`,
                },
              }
            )
            .then(({ data }) => {
              videoAux?.srcObject
                ?.getTracks()
                ?.forEach((track) => track.stop());
              setStatus("Processo concluído com sucesso");

            //  localStorage.removeItem("@data-prove-biometria:driverToken");
            //  localStorage.removeItem("@data-prove-biometria:driverId");

              history("/sucesso_validacao");
            })
            .catch((error) => {
              const d = error?.response?.data;
              if (d?.error)
                setStatus(d?.message || "Ocorreu um erro na biometria!");

              fase = 1;

              setTimeout(faceDetection(), 1000);

              clearTimeout(timerTimeout);

              setTentativas(tentativas + 1);
            });
        }

        if (fase === 1) {
          formData.append("image", blob, "image.jpg");

          const d = await axios
            .post(
              `${process.env.REACT_APP_BASE}/validacaocnh/remoto/biometria/basica/fase-01`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${driverToken}`,
                },
              }
            )
            .then(({ data }) => {
              fase = 2;
              setProcessData(data);
              //  appendPhoto();
              setStatus("Fase 1 concluído");
              setTimeout(faceDetection(), 1000);
            })
            .catch((error) => {
              const d = error?.response?.data;
              if (d?.error)
                setStatus(d?.message || "Ocorreu um erro na biometria!");

              fase = 1;

              faceDetection();

              clearTimeout(timerTimeout);

              setTentativas(tentativas + 1);
            });
        }
      }, "image/jpeg");
    }
  }, [photo]);

  const loadModels = () => {
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
      faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
      faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
      faceapi.nets.faceExpressionNet.loadFromUri("/models"),
    ]).then(() => {
      faceDetection();
    });
  };

  const faceDetection = () => {
    let videoAux = document?.getElementById("my-video");

    if (tentativas >= 5 && status != "Processo concluído com sucesso") {
      SweetAlert.small({
        type: "error",
        title: "Você atingiu o número de tentativas. Tente novamente!",
      });

      exit();
      return;
    }

    time = setInterval(async () => {
      if (fase === 1) setStatus("Detectando rosto");

      let detections = await faceapi
        .detectAllFaces(videoAux, new faceapi.TinyFaceDetectorOptions())
        .withFaceLandmarks()
        .withFaceExpressions();
      setInicia(true);

      if (!detections || detections.length === 0) {
        setStatus("Rosto não detectado");
        return;
      } /* else if (detections.length > 1) {
        setStatus("Foi detectado mais de um rosto");
        return;
      } */

      // setStatus('Aguarde verificando posição')

      canvasRef.current.innerHtml = faceapi.createCanvasFromMedia(videoAux);

      let video = document?.getElementById("my-video");
      const canvas = document?.createElement("canvas");
      canvas.width = videoAux?.videoWidth;
      canvas.height = videoAux?.videoHeight;
      canvas.getContext("2d").drawImage(videoAux, 0, 0);

      const face = detections[0];
      switch (fase) {
        case 1:
          function getTop(l) {
            return l.map((a) => a.y).reduce((a, b) => Math.min(a, b));
          }

          function getMeanPosition(l) {
            return l
              .map((a) => [a.x, a.y])
              .reduce((a, b) => [a[0] + b[0], a[1] + b[1]])
              .map((a) => a / l.length);
          }

          var eye_right = getMeanPosition(face.landmarks.getRightEye());
          var eye_left = getMeanPosition(face.landmarks.getLeftEye());
          var nose = getMeanPosition(face.landmarks.getNose());
          var mouth = getMeanPosition(face.landmarks.getMouth());
          var jaw = getTop(face.landmarks.getJawOutline());

          var rx = (jaw - mouth[1]) / face.detection.box.height;
          var ry =
            (eye_left[0] + (eye_right[0] - eye_left[0]) / 2 - nose[0]) /
            face.detection.box.width;

          // const y = ['-0.01', '-0.00', '0.00', '0.01']
          // const x = ['-0.36', '-0.59',]

          const y = ry.toFixed(2);
          const x = rx.toFixed(2);

          if (y >= -0.03 && y <= 0.3 && x <= -0.3 && x >= -0.5) {
            setStatus("Imagem capturada");
            clearTimeout(timerTimeout);
            setInicia(false);
            clearInterval(time);
            setPhoto((value) => canvas);
          } else setStatus("Posicione o rosto dentro do círculo");

          break;
        case 2:
          if (status != "Pronto, aguarde mais um pouco") {
            setStatus("Agora sorria");

            if (face.expressions.happy.toFixed(2) >= 0.97) {
              setStatus("Imagem capturada");
              clearTimeout(timerTimeout);
              setInicia(false);

              clearInterval(time);
              var id = window.setTimeout(function () { }, 0);

              while (id--) {
                window.clearTimeout(id); // will do nothing if no timeout with id is present
              }
              setPhoto((value) => canvas);
            }
          }
          break;

        default:
          console.log("nenhuma fase encontrada!");
          break;
      }
    }, 800);
  };

  return (
    <div className="bodyValidRemota backgroundThemeCnh">
      <div className="header-validacao" style={{ marginBottom: "20px" }}>
        <img
          style={{ width: 100 }}
          className="logo"
          src={logo}
          alt=""
        />
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <Spinner animation="border" variant="light" />

        <h3
          style={{ marginBottom: "0px", color: "white", marginLeft: "10px" }}
          className="titleFaceApi"
        >
          {status}
        </h3>
      </div>

      <div
        className="app__video"
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <Webcam
          id="my-video"
          crossOrigin="anonymous"
          ref={videoRef}
          autoPlay
          audio={false}
        ></Webcam>
      </div>
      <h4
        style={{ marginTop: "20px", color: "white" }}
        className="titleFaceApi"
      >
        Biometria Facial
      </h4>
      <canvas
        id="canvas"
        ref={canvasRef}
        style={{ display: "none" }}
        className="app__canvas"
      />
      <div
        id="screenshot"
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      ></div>
    </div>
  );
}

export default FaceId;
