export function money(tmp) {
  if (tmp === null) {
    return;
  } else {
    tmp = tmp?.toString();
    tmp = tmp?.replace(/\D/g, "");
    if (tmp.indexOf(".") === 0 || tmp.length > 1) {
      tmp = tmp?.replace(/\D/g, "");
      tmp = tmp + "";
      tmp = tmp?.replace(/([0-9]{2})$/g, ",$1");
      if (tmp.length > 6)
        tmp = tmp?.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }
    return tmp;
  }
}

export function moneyAux(i) {
  var v = i.replace(/\D/g, "");
  v = (v / 100).toFixed(2) + "";
  v = v.replace(".", ",");
  v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  return v;
}

export function somenteNumeros(e) {
  if (e !== null) {
    e = e?.toString();
    e = e?.replace(/\D/g, "");
    if (e?.length > 3) {
      e = e.replace(/(\d{1})(\d{1,3})$/, "$1.$2");
      e = e.replace(/(\d{3})(\d)/, "$1.$2");
      e = e.replace(/(\d{3})(\d)/, "$1.$2");
    }
  }
  return e;
}

function maskCurrency(valor, locale = "pt-BR", currency = "BRL") {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).format(valor);
}

export function formataMilhar(e) {
  let v = e.toString();
  if (v.length > 3) {
    v = v.replace(/\D/g, "");
    v = v.replace(/(\d{1})(\d{1,3})$/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
  }
  return v;
}

export function validaReal(e) {
  e = e?.toString();
  e = e?.replace(".", "");
  e = e?.replace(",", ".");
  return e;
}

export function cpfMask(v) {
  v = String(v);
  if (v !== null && v !== "null") {
    v.replace(/\D/g, "");
    v = v.replace(/\D/g, "");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  }

  return v;
}

export function CnpjMask(v) {
  v = v?.toString();
  v?.replace(/\D/g, "");
  v = v?.replace(/\D/g, "");
  v = v?.replace(/^(\d{2})(\d)/, "$1.$2");
  v = v?.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
  v = v?.replace(/\.(\d{3})(\d)/, ".$1/$2");
  v = v?.replace(/(\d{4})(\d)/, "$1-$2");
  return v;
}

export function validateCnh(cnh) {
  cnh = cnh.replace(/[^\d]+/g, "");
  if (cnh == "") return false;
  if (
    cnh.length != 11 ||
    cnh == "00000000000" ||
    cnh == "11111111111" ||
    cnh == "22222222222" ||
    cnh == "33333333333" ||
    cnh == "44444444444" ||
    cnh == "55555555555" ||
    cnh == "66666666666" ||
    cnh == "77777777777" ||
    cnh == "88888888888" ||
    cnh == "99999999999"
  )
    return false;
  return true;
}

export function cnhMask(v) {
  v = v.replace(/\D/g, "");
  // v = v.replace(/\D/g, "");
  // v = v.replace(/(\d{9})(\d{1,2})$/, "$1-$2")

  return v;
}

export function onlyLetters(v) {
  v = v.toString();
  v = v.replace(/[^a-zA-Z ]/g, "");
  return v;
}

export function onlyNumbers(v) {
  v.replace(/\D/g, "");
  v = v.replace(/\D/g, "");
  return v;
}
export function onlyLettersAndLetter(v) {
  v = v.toString();
  v = v.replace(/[^A-Za-z0-9]+/g, "");
  return v;
}

export function codeValidate(v) {
  v = v.replace(/\D/g, "");
  v = v.replace(/(\d{1})(\d)/, "$1-$2");
  v = v.replace(/(\d{1})(\d)/, "$1-$2");
  v = v.replace(/(\d{1})(\d)/, "$1-$2");
  v = v.replace(/(\d{1})(\d)/, "$1-$2");
  return v;
}

export function cnpjCpfMask(v) {
  v = v.replace(/\D/g, "");

  if (v.length <= 14) {
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  } else {
    v = v.substring(0, 14); // limita em 14 números
    v = v.replace(/^(\d{2})(\d)/, "$1.$2");
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
    v = v.replace(/(\d{4})(\d)/, "$1-$2");
  }

  return v;
}

export function cpfCnpjMask(v) {
  v = v.replace(/\D/g, "");
  if (v !== null) {
    v.replace(/\D/g, "");

    if (v.length <= 11) {
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
      v = v.replace(/^(\d{2})(\d)/, "$1.$2");
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
      v = v.replace(/(\d{4})(\d)/, "$1-$2");
    }
  }
  return v;
}

export const dateMask = (value) => {
  if (value) {
    value = value.toString();
    value = value.replace(/\-/g, "/");
  }
  return value;
};

export function phoneMask(a) {
  let v = a.toString();
  v = v.replace(/\D/g, "");
  v = v.replace(/^(\d\d)(\d)/g, "($1) $2");
  v = v.replace(/(\d{5})(\d)/, "$1-$2");
  return v;
}

export function validateCpf(a) {
  let cpf = a.toString();

  cpf = cpf.replace(/[^\d]+/g, "");
  if (cpf == "") return false;

  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length != 11 ||
    cpf == "00000000000" ||
    cpf == "11111111111" ||
    cpf == "22222222222" ||
    cpf == "33333333333" ||
    cpf == "44444444444" ||
    cpf == "55555555555" ||
    cpf == "66666666666" ||
    cpf == "77777777777" ||
    cpf == "88888888888" ||
    cpf == "99999999999"
  )
    return false;
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(10))) return false;
  return true;
}
export function validaCpfCnpj(val) {
  if (val.length == 14) {
    var cpf = val.trim();

    cpf = cpf.replace(/\./g, "");
    cpf = cpf.replace("-", "");
    cpf = cpf.split("");

    var v1 = 0;
    var v2 = 0;
    var aux = false;

    for (var i = 1; cpf.length > i; i++) {
      if (cpf[i - 1] != cpf[i]) {
        aux = true;
      }
    }

    if (aux == false) {
      return false;
    }

    for (var i = 0, p = 10; cpf.length - 2 > i; i++, p--) {
      v1 += cpf[i] * p;
    }

    v1 = (v1 * 10) % 11;

    if (v1 == 10) {
      v1 = 0;
    }

    if (v1 != cpf[9]) {
      return false;
    }

    for (var i = 0, p = 11; cpf.length - 1 > i; i++, p--) {
      v2 += cpf[i] * p;
    }

    v2 = (v2 * 10) % 11;

    if (v2 == 10) {
      v2 = 0;
    }

    if (v2 != cpf[10]) {
      return false;
    } else {
      return true;
    }
  } else if (val.length == 18) {
    var cnpj = val.trim();

    cnpj = cnpj.replace(/\./g, "");
    cnpj = cnpj.replace("-", "");
    cnpj = cnpj.replace("/", "");
    cnpj = cnpj.split("");

    var v1 = 0;
    var v2 = 0;
    var aux = false;

    for (var i = 1; cnpj.length > i; i++) {
      if (cnpj[i - 1] != cnpj[i]) {
        aux = true;
      }
    }

    if (aux == false) {
      return false;
    }

    for (var i = 0, p1 = 5, p2 = 13; cnpj.length - 2 > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v1 += cnpj[i] * p1;
      } else {
        v1 += cnpj[i] * p2;
      }
    }

    v1 = v1 % 11;

    if (v1 < 2) {
      v1 = 0;
    } else {
      v1 = 11 - v1;
    }

    if (v1 != cnpj[12]) {
      return false;
    }

    for (var i = 0, p1 = 6, p2 = 14; cnpj.length - 1 > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v2 += cnpj[i] * p1;
      } else {
        v2 += cnpj[i] * p2;
      }
    }

    v2 = v2 % 11;

    if (v2 < 2) {
      v2 = 0;
    } else {
      v2 = 11 - v2;
    }

    if (v2 != cnpj[13]) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

export function validatePhone(value) {
  value = value.replace(/[^\d]/g, "");

  let regex = /^([0-9]{2})(9[0-9]{8})$/g;
  let res = regex.test(value);
  return res;
}
