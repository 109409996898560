import { useState, useEffect, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import TermosValidacao from "../../TermosValidação";
import PaginaPassoAPassoValidacao from "../PassoAPasso";
import ValidacaoFace from "../ValidacaaBiometriaLiveness/FaceId";
//import ValidacaoAws from "../ValidacaaBiometriaLiveness/HandleAnalysisComplete";
import Api from "../../Utils/AxiosBiometria";
import SweetAlert from "../../Utils/SweetAlert";

import { useGeolocated } from "react-geolocated";
import Swal from "sweetalert2";
import LocalizacaoRecusada from "../../Assets/images/Logos/iconLocalizacaoError.png";
import Localizacao from "../../Assets/images/Logos/iconLocalizacao.png";

import styles from "./styles.module.css";
var sucesso = false;

function ValidacaoBiometria() {
  const { hash } = useParams();
  const location = useLocation();
  const [permissions, setPermissions] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const [step, setStep] = useState(0);
  const [code, setCode] = useState("");
  const [cpf, setCpf] = useState("");
  const [coordinates, setCoordinates] = useState({
    endereco: "",
    latitude: null,
    longitude: null,
  });

  const { coords, isGeolocationAvailable, isGeolocationEnabled, getPosition } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: Infinity,
      },
      watchPosition: true,
      userDecisionTimeout: undefined,
      suppressLocationOnMount: true,
      geolocationProvider: navigator.geolocation,
      isOptimisticGeolocationEnabled: true,
      watchLocationPermissionChange: true,
      onSuccess: () => {
        sucesso = true;
      },
      onError: (error) => {
        Swal.fire({
          title:
            '<h2 id={swal2-title} className="swal2-title" style="color:black;fontSize:27px">Geolocalização desabilitada!</h2>',

          html: ' <div id="swal2-content" className="swal2-html-container" style="color:gray" >Para o bom funcionamento do processo, favor habilite a sua geolocalização em configurações e depois atualize essa página.</div>',

          imageUrl: LocalizacaoRecusada,
          imageHeight: 60,
          imageAlt: "Custom image",
          showConfirmButton: false,
          allowOutsideClick: false,
        });
        console.log(error?.message);
        {
          /* Api.post("/antares", {
          message:
            "42e7e29f, Erro ao acessar a localização do dispositivo - LINHA 76 | index.jsx.",
          level: "1",
          data: JSON.stringify(error),
        }); */
        }
      },
    });

  useEffect(() => {
    timeLoc();
  }, []);
  useEffect(() => {
    if (permissions) {
      if (navigator.geolocation) {
        if (permissions != "denied") {
          navigator.permissions
            .query({ name: "geolocation" })
            .then(function (result) {
              console.log(permissions);
              if (result.state != "granted") {
                // getPosition();
                Swal.fire({
                  title:
                    '<h2 id={swal2-title} className="swal2-title" style="color:black;fontSize:27px">Precisamos da sua localização!</h2>',

                  html: ' <div id="swal2-content" className="swal2-html-container" style="color:gray" >Para prosseguir aceite a permissão de localização do seu navegador.</div>',
                  confirmButtonColor: "#142939",

                  imageUrl: Localizacao,
                  imageHeight: 60,
                  imageAlt: "Custom image",
                  showConfirmButton: true,
                  closeOnClickOutside: false,

                  confirmButtonText: "Habilitar",
                  allowOutsideClick: false,
                  preConfirm: function () {
                    return new Promise(function (resolve) {
                      getPosition();
                      let interval = setInterval(() => {
                        if (sucesso) {
                          resolve();
                        }
                      }, 1000);

                      return () => clearInterval(interval);
                    });
                  },
                }).then((e) => {});
              } else {
                getPosition();
                Swal.close();
              }
            })
            .catch((error) => {
              {
                /*Api.post("/antares", {
                message:
                  "c67d898c, Erro ao obter a permissão da geolocalização do dispositivo  - LINHA 129 | index.jsx.",
                level: "4",
                data: JSON.stringify(error),
              });*/
              }
            });
        } else {
          Swal.fire({
            title:
              '<h2 id={swal2-title} className="swal2-title" style="color:black;fontSize:27px">Geolocalização desabilitada!</h2>',

            html: ' <div id="swal2-content" className="swal2-html-container" style="color:gray" >Para o bom funcionamento do processo, favor habilite a sua geolocalização em configurações e depois atualize essa página.</div>',

            imageUrl: LocalizacaoRecusada,
            imageHeight: 60,
            imageAlt: "Custom image",
            showConfirmButton: false,
            allowOutsideClick: false,
          });
        }
      } else {
        {
          /* Api.post("/antares", {
          message:
            "cfcec989, A geolocalização não é suportada por este navegador  - LINHA 129 | index.jsx.",
          level: "4",
          data: "A geolocalização não é suportada por este navegador.",
        }); */
        }
        console.log("Geolocation is not supported by this browser.");
      }
    }
  }, [permissions]);

  function timeLoc() {
    setInterval(() => {
      if (navigator.geolocation) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then(function (result) {
            // console.log(result);
            if (result.state == "granted") {
              setPermissions("granted");
            } else if (result.state == "prompt") {
              setPermissions("prompt");
            } else if (result.state == "denied") {
              setPermissions("denied");
            }
          });
      }
    }, 1000);
  }
  function nextStep() {
    setStep((prevStep) => prevStep + 1);
  }

  function getCoordinates({ endereco, latitude, longitude }) {
    setCoordinates({
      endereco,
      latitude,
      longitude,
    });
  }

  const handleBiometriaLogin = useCallback(async () => {
    setLoadingButton(true);
    try {
      const response = await Api.post("/biometria/remota/login", {
        token: hash,
        cpf: cpf.toString().replace(/[^0-9]/g, ""),
        codigo_acesso: code.toString().replace(/[^0-9]/g, ""),
        geo_localizacao: {
          endereço: coordinates.endereco,
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
        },
      });

      if (response) {
        localStorage.setItem(
          "@data-prove-biometria:driverToken",
          response.data.access_token
        );
        localStorage.setItem(
          "@data-prove-biometria:driverId",
          response.data.id
        );
        setLoadingButton(false);
        nextStep();
      }
    } catch (error) {
      setLoadingButton(false);
      SweetAlert.small({ type: "error", title: `${error?.response?.data}` });
      /*Api.post("/antares", {
        message:
          "1c2d3435, Erro ao realizar o login na validaçao de biometria  - LINHA 61 | index.jsx",
        level: "2",
        data: error?.toString(),
      });*/
      console.log(error);
    }
  }, [hash, coordinates, code, cpf]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location?.search);

    const code = urlParams?.get("c");
    const cpf = urlParams?.get("cpf");

    if (code && cpf) {
      setCode(code);
      setCpf(cpf);
    }
  }, [location]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location?.search);

    urlParams.set("step", step);
  }, [location, step]);

  useEffect(() => {
    if (
      code?.length &&
      cpf?.length &&
      coordinates.latitude &&
      coordinates.longitude
    ) {
      setTimeout(() => {
        handleBiometriaLogin();
      }, 1000);
    }
  }, [coordinates, code, cpf, handleBiometriaLogin]);

  return (
    <>
      {step === 0 && (
        <main className={styles.container}>
          <TermosValidacao
            hash={hash}
            step={step}
            setStep={setStep}
            handleNextStep={nextStep}
            getCoordinates={getCoordinates}
            coords={coords}
            isGeolocationAvailable={isGeolocationAvailable}
            isGeolocationEnabled={isGeolocationEnabled}
            loadingButton={loadingButton}
            setLoadingButton={setLoadingButton}
          />
        </main>
      )}
      {step === 1 && <PaginaPassoAPassoValidacao handleNextStep={nextStep} />}
      {step === 2 && <ValidacaoFace setStep={setStep} />}
    </>
  );
}

export default ValidacaoBiometria;
