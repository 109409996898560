/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { Container, Image, Col, Row } from "react-bootstrap";

import LogoFlex from "../../Assets/images/Logos/logo_dataprove.png";

export default () => {
  return (
    <React.Fragment>
      <Container fluid id="container-not-found">
        <Row className="h-100 d-flex flex-column align-items-center justify-content-center">
          <Image
            src={LogoFlex}
            style={{
              position: "absolute",
              width: "100px",
              top: "2%",
              left: "2%",
            }}
          />

          <Col
            md={4}
            className="border border-1 bg-white p-5 text-center"
            style={{ color: "#707070", borderRadius: "10px" }}
          >
            <h1 style={{ fontSize: "7.5rem", fontWeight: "lighter" }}>404</h1>
            <h2>Página não encontrada</h2>
            <h6 className="mb-5 font-weight-light">
              Não encontramos nada por aqui...
            </h6>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
