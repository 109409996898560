import axios from "axios";
import { getTokenBiometria } from "../Services/Auth";
const URL = process.env.REACT_APP_BASE;
const ApiBiometria = axios.create({ baseURL: URL });

ApiBiometria.interceptors.request.use(async (config) => {
  const token = getTokenBiometria() || null;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

function logoutUser() {
  localStorage.removeItem("userid");
  localStorage.removeItem("AUTH-REQUEST-PAYLOAD");
  localStorage.removeItem("AUTH-REQUEST-TOKEN");
  localStorage.removeItem("token");
  localStorage.removeItem("permissoes");
  localStorage.removeItem("TOKEN-BIO");
  localStorage.removeItem("TOKEN-BIO-DATA");
  window.location.href = "https://www.flexconsulta.com.br";
}

ApiBiometria.interceptors.response.use(
  function (response) {
    if (response.status === 304) logoutUser();
    return response;
  },
  function (error) {
    if (error.response.status === 403) logoutUser();
    return Promise.reject(error);
  },
  function (error) {
    if (error.response.status === 401) logoutUser();
    return Promise.reject(error);
  }
);

export default ApiBiometria;
